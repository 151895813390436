define("ember-google-maps/utils/options-and-events", ["exports", "@ember/string", "@ember/runloop", "ember-google-maps/utils/platform"], function (_exports, _string, _runloop, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OptionsAndEvents = void 0;
  _exports.addEventListener = addEventListener;
  _exports.addEventListeners = addEventListeners;
  _exports.ignoredOptions = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const ignoredOptions = _exports.ignoredOptions = ['lat', 'lng', 'getContext'];
  const IGNORED = Symbol('Ignored'),
    EVENT = Symbol('Event'),
    OPTION = Symbol('Option'),
    OPTIONS = Symbol('Options'),
    EVENTS = Symbol('Events');
  function isEvent(name) {
    return name.startsWith('on');
  }
  function isOnceEvent(name) {
    return name.startsWith('onceOn');
  }
  class OptionsAndEvents {
    constructor(args) {
      _defineProperty(this, "whosThatProp", new Map());
      _defineProperty(this, OPTION, new Set());
      _defineProperty(this, EVENT, new Set());
      this.args = args;
      this.ignoredSet = new Set(ignoredOptions);

      // Sort and cache the arguments by type.
      this.parse();
      let getFromArgs = this.getFromArgs.bind(this);
      if (_platform.HAS_NATIVE_PROXY) {
        let target = Object.create(null);
        let optionsHandler = new ArgsProxyHandler(this[OPTION], getFromArgs);
        let eventsHandler = new ArgsProxyHandler(this[EVENT], getFromArgs);
        this.options = new Proxy(target, optionsHandler);
        this.events = new Proxy(target, eventsHandler);
      } else {
        this.options = newNoProxyFallback(this[OPTION], getFromArgs);
        this.events = newNoProxyFallback(this[EVENT], getFromArgs);
      }
    }
    getFromArgs(prop) {
      let identity = this.whosThatProp.get(prop);
      switch (identity) {
        case OPTIONS:
          return this.args.options[prop];
        case EVENTS:
          return this.args.events[prop];
        case OPTION:
        case EVENT:
          return this.args[prop];
      }
    }
    parse() {
      for (let prop in this.args) {
        let identity = this.identify(prop);
        switch (identity) {
          case OPTION:
          case EVENT:
            this[identity].add(prop);
            this.whosThatProp.set(prop, identity);
            break;
          case OPTIONS:
            for (let innerProp in this.args[prop]) {
              this[OPTION].add(innerProp);
              this.whosThatProp.set(innerProp, identity);
            }
            break;
          case EVENTS:
            for (let innerProp in this.args[prop]) {
              this[EVENT].add(innerProp);
              this.whosThatProp.set(innerProp, identity);
            }
            break;
          case IGNORED:
            break;
        }
      }
    }
    identify(prop) {
      if (this.isIgnored(prop)) {
        return IGNORED;
      }
      if (prop === 'options') {
        return OPTIONS;
      }
      if (prop === 'events') {
        return EVENTS;
      }
      if (this.isEvent(prop)) {
        return EVENT;
      }
      return OPTION;
    }
    isEvent(prop) {
      return isOnceEvent(prop) || isEvent(prop);
    }
    isIgnored(prop) {
      return this.ignoredSet.has(prop);
    }
  }
  _exports.OptionsAndEvents = OptionsAndEvents;
  class ArgsProxyHandler {
    constructor(cache, getFromArgs) {
      this.cache = cache;
      this.getFromArgs = getFromArgs;
      this.setCache = new Map();
    }
    get(_target, prop) {
      return this.setCache.get(prop) ?? this.getFromArgs(prop);
    }
    set(_target, prop, value) {
      if (value !== undefined) {
        this.setCache.set(prop, value);
      } else {
        this.setCache.delete(prop);
      }

      // Never fail to set a value
      return true;
    }
    has(_target, prop) {
      return this.setCache.has(prop) || this.cache.has(prop);
    }
    ownKeys() {
      return Array.from(new Set([...this.setCache.keys(), ...this.cache.values()]));
    }
    isExtensible() {
      return false;
    }
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true
      };
    }
  }
  function newNoProxyFallback(propSet, getFromArgs) {
    let obj = {};
    propSet.forEach(prop => {
      Object.defineProperty(obj, prop, {
        enumerable: true,
        configurable: true,
        get() {
          return obj[prop] ?? getFromArgs(prop);
        },
        set(prop, value) {
          if (value === undefined) {
            delete obj[prop];
          } else {
            obj[prop] = value;
          }
        }
      });
    });
    return obj;
  }

  /* Events */

  function addEventListener(target, originalEventName, action, payload = {}) {
    let isDom = target instanceof Element;
    let isOnce = isOnceEvent(originalEventName);
    let maybeDom = isDom ? 'Dom' : '';
    let maybeOnce = isOnce ? 'Once' : '';
    let listenerType = `add${maybeDom}Listener${maybeOnce}`;
    let addGoogleListener = google.maps.event[listenerType];
    let eventName = isOnce ? originalEventName.slice(6) // onceOn
    : originalEventName.slice(2); // on

    eventName = (0, _string.decamelize)(eventName);
    function callback(googleEvent) {
      let params = _objectSpread({
        event: window.event,
        googleEvent,
        eventName,
        target
      }, payload);
      (0, _runloop.next)(target, action, params);
    }
    let listener = addGoogleListener(target, eventName, callback);
    return {
      name: eventName,
      listener,
      remove: () => listener.remove()
    };
  }

  /**
   * Add event listeners on a target object using the cross-browser event
   * listener library provided by the Google Maps API.
   *
   * @param {Object} target
   * @param {Events} events
   * @param {[Object]} payload = {} An optional object of additional parameters
   *     to include with the event payload.
   * @return {google.maps.MapsEventListener[]} An array of bound event listeners
   *     that should be used to remove the listeners when no longer needed.
   */
  function addEventListeners(target, events, payload = {}) {
    return Object.entries(events).map(([originalEventName, action]) => {
      return addEventListener(target, originalEventName, action, payload);
    });
  }
});