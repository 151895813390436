define("ember-paper/components/paper-sidenav-container", ["exports", "@ember/component", "@ember/string"], function (_exports, _component, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/require-tagless-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperSidenavContainer
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['flex', 'layout-row'],
    attributeBindings: ['style'],
    style: (0, _string.htmlSafe)('overflow: hidden')
  });
});