define("ember-paper/mixins/validation-mixin", ["exports", "@ember/object/mixin", "@ember/debug", "@ember/array", "@ember/object", "@ember/object/computed", "@ember/string", "@ember/utils", "ember-paper/validators/required", "ember-paper/validators/min", "ember-paper/validators/max", "ember-paper/validators/minlength", "ember-paper/validators/maxlength", "ember-paper/utils/invoke-action"], function (_exports, _mixin, _debug, _array, _object, _computed, _string, _utils, _required, _min, _max, _minlength, _maxlength, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get, ember/avoid-leaking-state-in-ember-objects */
  /**
   * @module ember-paper
   */

  /**
   * In order to make validation generic it is required that components using the validation mixin
   * specify what property the validation logic should be based on.
   *
   * @public
   *
   * @return computed property that depends on the supplied property name
   */
  function buildComputedValidationMessages(property, validations = [], customValidations = []) {
    let validationParams = validations.map(v => (0, _object.get)(v, 'param')).filter(v => !(0, _utils.isBlank)(v));
    let customValidationParams = customValidations.map(v => (0, _object.get)(v, 'param')).filter(v => !(0, _utils.isBlank)(v));
    return (0, _object.computed)(property, 'errors.[]', 'customValidations.[]', ...validationParams, ...customValidationParams, function () {
      let validations = (0, _array.A)();
      let messages = (0, _array.A)();

      // built-in validations
      validations.pushObjects(this.validations());

      // custom validations
      let customValidations = this.customValidations;
      (true && !((0, _array.isArray)(customValidations)) && (0, _debug.assert)('`customValidations` must be an array', (0, _array.isArray)(customValidations)));
      validations.pushObjects(customValidations);

      // execute validations
      let currentValue = this.get(property);
      validations.forEach(validation => {
        (true && !(validation && validation.validate && typeof validation.validate === 'function') && (0, _debug.assert)('validation must include a `validate(value)` function', validation && validation.validate && typeof validation.validate === 'function'));
        try {
          let valParam = (0, _object.get)(validation, 'param');
          let paramValue = valParam ? this.get(valParam) : undefined;
          if (!validation.validate(currentValue, paramValue)) {
            let message = this.get(`errorMessages.${valParam}`) || (0, _object.get)(validation, 'message');
            messages.pushObject({
              message: (0, _string.loc)(message.string || message, paramValue, currentValue)
            });
          }
        } catch (error) {
          (true && (0, _debug.warn)(`Exception with validation: ${validation} ${error}`, false));
        }
      });

      // error messages array
      let errors = this.errors || [];
      (true && !((0, _array.isArray)(errors)) && (0, _debug.assert)('`errors` must be an array', (0, _array.isArray)(errors)));
      messages.pushObjects(errors.map(e => {
        return (0, _object.get)(e, 'message') ? e : {
          message: e
        };
      }));
      return messages;
    });
  }

  /**
   * @class ValidationMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create({
    validationErrorMessages: null,
    lastIsInvalid: undefined,
    validationProperty: null,
    // property that validation should be based on

    init() {
      this._super(...arguments);
      (true && !(this.validationProperty) && (0, _debug.assert)('validationProperty must be set', this.validationProperty));
      if (!this.validationErrorMessages) {
        let computedValidationMessages = buildComputedValidationMessages(this.validationProperty, this.validations(), this.customValidations);
        (0, _object.defineProperty)(this, 'validationErrorMessages', computedValidationMessages);
      }
    },
    hasErrorMessages: (0, _computed.bool)('validationErrorMessages.length'),
    /**
     * The result of isInvalid is appropriate for controlling the display of
     * validation error messages. It also may be used to distinguish whether
     * the input would be considered valid after it is touched.
     *
     * @public
     *
     * @return {boolean} Whether the input is or would be invalid.
     *    false: input is valid (touched or not), or is no longer rendered
     *    true: input has been touched and is invalid.
     */
    isInvalid: (0, _computed.reads)('hasErrorMessages'),
    isValid: (0, _computed.not)('isInvalid'),
    /**
     * Return the built-in validations.
     *
     * May be overridden to provide additional built-in validations. Be sure to
     * call this._super() to retrieve the standard validations.
     *
     * @public
     */
    validations() {
      return [_required.default, _min.default, _max.default, _minlength.default, _maxlength.default];
    },
    notifyValidityChange() {
      let isValid = this.isValid;
      let lastIsValid = this.lastIsValid;
      let isTouched = this.isTouched;
      let lastIsTouched = this.lastIsTouched;
      if (lastIsValid !== isValid || lastIsTouched !== isTouched) {
        (0, _invokeAction.invokeAction)(this, 'onValidityChange', isValid);
        this.set('lastIsValid', isValid);
        this.set('lastIsTouched', isTouched);
      }
    },
    customValidations: [],
    errors: []
  });
});