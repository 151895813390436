define("ember-paper/components/paper-tabs", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/string", "@ember/runloop", "ember-paper/templates/components/paper-tabs", "ember-composability-tools", "ember-paper/mixins/color-mixin", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _service, _computed, _component2, _string, _runloop, _paperTabs, _emberComposabilityTools, _colorMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-mixins, ember/no-component-lifecycle-hooks, ember/classic-decorator-no-classic-methods, ember/no-get */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PaperTabs = _exports.default = (_dec = (0, _component.layout)(_paperTabs.default), _dec2 = (0, _component.tagName)('md-tabs'), _dec3 = (0, _component.classNames)('md-no-tab-content', 'md-default-theme'), _dec4 = (0, _component.attributeBindings)('borderBottom:md-border-bottom'), _dec5 = (0, _object.computed)('noInkBar', '_selectedTab.{width,left}', 'wrapperWidth'), _dec6 = (0, _object.computed)('currentOffset'), _dec7 = (0, _object.computed)('shouldPaginate', 'center'), _dec8 = (0, _object.computed)('shouldPaginate', 'currentStretch'), _dec9 = (0, _computed.gt)('currentOffset', 0), _dec10 = (0, _object.computed)('wrapperWidth', 'currentOffset', 'canvasWidth'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class PaperTabs extends _component2.default.extend(_emberComposabilityTools.ParentMixin, _colorMixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _defineProperty(this, "selected", 0);
      // select first tab by default
      _defineProperty(this, "noInkBar", false);
      _defineProperty(this, "noInk", false);
      _defineProperty(this, "ariaLabel", null);
      _defineProperty(this, "stretch", 'sm');
      _defineProperty(this, "movingRight", true);
      _defineProperty(this, "shouldPaginate", true);
      _defineProperty(this, "currentOffset", 0);
      _initializerDefineProperty(this, "canPageBack", _descriptor2, this);
    }
    get inkBar() {
      if (this.noInkBar) {
        return null;
      }
      let selectedTab = this._selectedTab;
      if (!selectedTab || selectedTab.get('left') === undefined) {
        return null;
      }
      return {
        left: selectedTab.get('left'),
        right: this.wrapperWidth - selectedTab.get('left') - selectedTab.get('width')
      };
    }
    get paginationStyle() {
      return (0, _string.htmlSafe)(`transform: translate3d(-${this.currentOffset}px, 0px, 0px);`);
    }
    get shouldCenter() {
      return !this.shouldPaginate && this.center;
    }
    get shouldStretch() {
      return !this.shouldPaginate && this.currentStretch;
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.updateCanvasWidth = () => {
        (0, _runloop.join)(() => {
          this.updateDimensions();
          this.updateStretchTabs();
        });
      };
      window.addEventListener('resize', this.updateCanvasWidth);
      window.addEventListener('orientationchange', this.updateCanvasWidth);
      (0, _runloop.scheduleOnce)('afterRender', this, this.fixOffsetIfNeeded);
    }
    didRender() {
      super.didRender(...arguments);
      // this makes sure that the tabs react to stretch and center changes
      // this method is also called whenever one of the tab is re-rendered (content changes)
      this.updateSelectedTab();
      this.updateCanvasWidth();
    }

    /**
     * Updates the currently selected tab only once all the <paper-tab> has rendered.
     *
     * If we were to use a computed property the observer would get triggered once per
     * nested <paper-tab> because we pass the 'selected' property to them that will
     * invalidate their 'isSelected' property.
     */
    updateSelectedTab() {
      let selectedTab = this.childComponents.findBy('isSelected');
      let previousSelectedTab = this._selectedTab;
      if (selectedTab === previousSelectedTab) {
        return;
      }
      this.set('movingRight', !selectedTab || !previousSelectedTab || previousSelectedTab.get('left') < selectedTab.get('left'));
      this.set('_selectedTab', selectedTab);
      (0, _runloop.scheduleOnce)('afterRender', this, this.fixOffsetIfNeeded);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      window.removeEventListener('resize', this.updateCanvasWidth);
      window.removeEventListener('orientationchange', this.updateCanvasWidth);
    }
    registerChild(childComponent) {
      super.registerChild(...arguments);
      // automatically set value if not manually set
      if (childComponent.get('value') === undefined) {
        let length = this.childComponents.get('length');
        childComponent.set('value', length - 1);
      }
    }
    fixOffsetIfNeeded() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      let canvasWidth = this.canvasWidth;
      let currentOffset = this.currentOffset;
      let tabLeftOffset = this.get('_selectedTab.left');
      let tabRightOffset = tabLeftOffset + this.get('_selectedTab.width');
      let newOffset;
      if (canvasWidth < this.get('_selectedTab.width')) {
        // align with selectedTab if canvas smaller than selected tab
        newOffset = tabLeftOffset;
      } else if (tabRightOffset - currentOffset > canvasWidth) {
        // ensure selectedTab is not partially hidden on the right side
        newOffset = tabRightOffset - canvasWidth;
      } else if (tabLeftOffset < currentOffset) {
        // ensure selectedTab is not partially hidden on the left side
        newOffset = tabLeftOffset;
      } else {
        newOffset = currentOffset;
      }
      if (newOffset === currentOffset) {
        return;
      }
      this.set('currentOffset', newOffset);
    }
    updateDimensions() {
      let canvasWidth = this.element.querySelector('md-tabs-canvas').offsetWidth;
      let wrapperWidth = this.element.querySelector('md-pagination-wrapper').offsetWidth;
      this.childComponents.invoke('updateDimensions');
      this.set('canvasWidth', canvasWidth);
      this.set('wrapperWidth', wrapperWidth);
      this.set('shouldPaginate', wrapperWidth > canvasWidth);
    }
    updateStretchTabs() {
      let stretch = this.stretch;
      let currentStretch;

      // if `true` or `false` is specified, always/never "stretch tabs"
      // otherwise proceed with normal matchMedia test
      if (typeof stretch === 'boolean') {
        currentStretch = stretch;
      } else {
        let mediaQuery = this.constants.MEDIA[stretch] || stretch;
        currentStretch = window.matchMedia(mediaQuery).matches;
      }
      this.set('currentStretch', currentStretch);
    }
    get canPageForward() {
      return this.wrapperWidth - this.currentOffset > this.canvasWidth;
    }
    previousPage() {
      let tab = this.childComponents.find(t => {
        // ensure we are no stuck because of a tab with a width > canvasWidth
        return t.get('left') + t.get('width') >= this.currentOffset;
      });
      if (tab) {
        let left = Math.max(0, tab.get('left') - this.canvasWidth);
        this.set('currentOffset', left);
      }
    }
    nextPage() {
      let tab = this.childComponents.find(t => {
        // ensure tab's offset is greater than current
        // otherwise if the tab's width is greater than canvas we cannot paginate through it
        return t.get('left') > this.currentOffset
        // paginate until the first partially hidden tab
        && t.get('left') + t.get('width') - this.currentOffset > this.canvasWidth;
      });
      if (tab) {
        this.set('currentOffset', tab.get('left'));
      }
    }
    localOnChange(selected) {
      // support non DDAU scenario
      if (this.onChange) {
        (0, _invokeAction.invokeAction)(this, 'onChange', selected.get('value'));
      } else {
        this.set('selected', selected.get('value'));
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "constants", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "inkBar", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inkBar"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "paginationStyle", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldCenter", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldCenter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldStretch", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldStretch"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "canPageBack", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "canPageForward", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "canPageForward"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "previousPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "previousPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "nextPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "nextPage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "localOnChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "localOnChange"), _class2.prototype), _class2)) || _class) || _class) || _class) || _class);
});