define("@mainmatter/ember-api-actions/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.adapterAction = adapterAction;
  _exports.apiAction = apiAction;
  const VALID_METHODS = ['GET', 'HEAD', 'POST', 'PUT', 'DELETE', 'OPTIONS', 'PATCH'];
  async function apiAction(record, {
    requestType = 'updateRecord',
    method,
    path,
    data,
    adapterOptions
  }) {
    (true && !(method) && (0, _debug.assert)(`Missing \`method\` option`, method));
    (true && !(VALID_METHODS.includes(method)) && (0, _debug.assert)([`Invalid \`method\` option: ${method}`, `Valid options: ${VALID_METHODS.join(', ')}`].join('\n'), VALID_METHODS.includes(method)));
    let modelClass = record.constructor;
    let modelName = modelClass.modelName;
    let adapter = record.store.adapterFor(modelName);
    let snapshot = record._createSnapshot();
    if (adapterOptions) {
      snapshot.adapterOptions = adapterOptions;
    }
    let baseUrl = adapter.buildURL(modelName, record.id, snapshot, requestType);
    let url = path ? `${baseUrl}/${path}` : baseUrl;
    return await adapter.ajax(url, method, {
      data
    });
  }

  /** @experimental */
  async function adapterAction(adapter, modelName, {
    requestType = 'createRecord',
    method,
    path,
    data
  }) {
    (true && !(method) && (0, _debug.assert)(`Missing \`method\` option`, method));
    (true && !(VALID_METHODS.includes(method)) && (0, _debug.assert)([`Invalid \`method\` option: ${method}`, `Valid options: ${VALID_METHODS.join(', ')}`].join('\n'), VALID_METHODS.includes(method)));
    let baseUrl = adapter.buildURL(modelName, null, null, requestType);
    let url = path ? `${baseUrl}/${path}` : baseUrl;
    return await adapter.ajax(url, method, {
      data
    });
  }
});