define("ember-paper/components/paper-sidenav-toggle", ["exports", "@ember/service", "@ember/component", "ember-paper/templates/components/paper-sidenav-toggle"], function (_exports, _service, _component, _paperSidenavToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperSidenavToggle
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperSidenavToggle.default,
    tagName: '',
    name: 'default',
    paperSidenav: (0, _service.inject)(),
    toggle() {
      this.paperSidenav.toggle(this.name);
    }
  });
});