define("ember-google-maps/components/g-map/info-window", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "ember-google-maps/utils/helpers"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.container}}
    {{#in-element this.container}}
      {{#if (has-block)}}
        {{yield this.publicAPI}}
      {{else}}
        {{this.content}}
      {{/if}}
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "T93aBLNq",
    "block": "[[[41,[30,0,[\"container\"]],[[[40,[[[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[30,0,[\"publicAPI\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"content\"]]],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,2],[[30,0,[\"container\"]]],null]]],[]],null]],[\"&default\"],false,[\"if\",\"in-element\",\"-in-el-null\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/info-window.hbs",
    "isStrictMode": false
  });
  let InfoWindow = _exports.default = (_class = class InfoWindow extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      // Can’t use a fragment here because Google Maps consumes it.
      _initializerDefineProperty(this, "container", _descriptor, this);
    }
    get name() {
      return 'infoWindows';
    }
    get isOpen() {
      return Boolean(this.infoWindow.getMap());
    }

    // TODO: Sanitize this.args.content?
    get content() {
      return this.args.content ?? this.container;
    }
    get newOptions() {
      let options = this.options;
      if (!options.target && !this.args.position) {
        options.position = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }
      if (options.isOpen) {
        options.content = this.content;
      }
      return options;
    }
    setup() {
      let infoWindow = new google.maps.InfoWindow(this.newOptions);

      // This is kind of annoying. Maybe we can refactor stuff to not use `this`.
      this.infoWindow = infoWindow;
      this.addEventsToMapComponent(infoWindow, this.events, this.publicAPI);
      this.toggleOpen();
      return infoWindow;
    }
    update(infoWindow) {
      infoWindow.setOptions(this.newOptions);
      this.toggleOpen();
    }
    toggleOpen() {
      let shouldBeOpen = this.args.isOpen ?? false;
      if (shouldBeOpen === this.isOpen) {
        return;
      }
      if (shouldBeOpen) {
        this.open();
      } else {
        this.close();
      }
    }
    open() {
      this.infoWindow.open(this.map, this.options.target);
    }
    close() {
      this.infoWindow.close();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _window, _window$document;
      return (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.createElement('div');
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InfoWindow);
});