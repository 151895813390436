define("ember-paper/components/paper-radio-base", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/debug", "ember-paper/templates/components/paper-radio-base", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/utils/invoke-action"], function (_exports, _component, _service, _object, _debug, _paperRadioBase, _focusableMixin, _colorMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/no-mixins, ember/require-tagless-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperRadio
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   */
  var _default = _exports.default = _component.default.extend(_focusableMixin.default, _colorMixin.default, {
    layout: _paperRadioBase.default,
    tagName: 'md-radio-button',
    classNames: ['md-default-theme'],
    classNameBindings: ['checked:md-checked'],
    attributeBindings: ['role', 'ariaChecked:aria-checked', 'ariaLabel:aria-label'],
    toggle: false,
    role: 'radio',
    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,
    constants: (0, _service.inject)(),
    // Lifecycle hooks
    init() {
      (true && !(this.onChange !== undefined) && (0, _debug.assert)('{{paper-radio}} requires an `onChange` action or null for no action.', this.onChange !== undefined));
      this._super(...arguments);
    },
    checked: (0, _object.computed)('groupValue', 'value', function () {
      return this.groupValue === this.value;
    }),
    ariaChecked: (0, _object.computed)('checked', function () {
      return this.checked ? 'true' : 'false';
    }),
    labelId: (0, _object.computed)('elementId', function () {
      return `${this.elementId}-label`;
    }),
    click() {
      if (!this.disabled) {
        if (this.toggle) {
          (0, _invokeAction.invokeAction)(this, 'onChange', this.checked ? null : this.value);
        } else {
          (0, _invokeAction.invokeAction)(this, 'onChange', this.value);
        }
      }
      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    },
    keyPress(ev) {
      if (ev.which === this.constants.KEYCODE.SPACE || ev.which === this.constants.KEYCODE.ENTER) {
        ev.preventDefault();
        this.click();
      }
    }
  });
});