define("ember-google-maps/components/g-map/overlay", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "ember-google-maps/utils/helpers"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _object, _internals, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{#if this.container}}
      {{#in-element this.container}}
        <div id={{this.id}} ...attributes {{g-map/did-insert this.getOverlay}}>{{yield}}</div>
      {{/in-element}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "4j+g1Oi6",
    "block": "[[[41,[48,[30,2]],[[[41,[30,0,[\"container\"]],[[[40,[[[1,\"      \"],[11,0],[16,1,[30,0,[\"id\"]]],[17,1],[4,[38,4],[[30,0,[\"getOverlay\"]]],null],[12],[18,2,null],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,0,[\"container\"]]],null]]],[]],null]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"in-element\",\"-in-el-null\",\"g-map/did-insert\",\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/overlay.hbs",
    "isStrictMode": false
  });
  let OverlayView = _exports.default = (_class = class OverlayView extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "id", `ember-google-maps-overlay-${(0, _internals.guidFor)(this)}`);
      _initializerDefineProperty(this, "container", _descriptor, this);
    }
    get name() {
      return 'overlays';
    }
    get zIndex() {
      return this.args.zIndex ?? 'auto';
    }
    get paneName() {
      return this.args.paneName ?? 'overlayMouseTarget';
    }
    get position() {
      let {
        lat,
        lng,
        position
      } = this.args;
      return position ?? (0, _helpers.toLatLng)(lat, lng);
    }
    setup() {
      let Overlay = new google.maps.OverlayView();
      Overlay.onAdd = () => this.onAdd();
      Overlay.onRemove = () => this.onRemove();
      Overlay.draw = () => this.draw();

      // Make sure we don’t run “draw” before Google Maps has done so first.
      Overlay.didDraw = false;
      Overlay.setMap(this.map);

      // Explicitly track options here, as the Google Maps performs the setup
      // asynchronously.
      return [Overlay, Object.values(this.options)];
    }

    // TODO: support changing pane?
    update(overlay) {
      if (overlay.didDraw) {
        overlay.draw();
      }
    }
    onAdd() {
      let panes = this.mapComponent.getPanes();
      this.targetPane = panes[this.paneName];
      this.targetPane.appendChild(this.overlayElement);
      this.addEventsToMapComponent(this.overlayElement, this.events, this.publicAPI);
    }
    draw() {
      var _this$mapComponent;
      let {
        position,
        zIndex
      } = this;
      let overlayProjection = this.mapComponent.getProjection();
      let point = overlayProjection.fromLatLngToDivPixel(position);
      this.overlayElement.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      z-index: ${zIndex};
      transform: translateX(${point.x}px) translateY(${point.y}px);
    `;
      (_this$mapComponent = this.mapComponent).didDraw || (_this$mapComponent.didDraw = true);
    }
    onRemove() {
      let parentNode = this.overlayElement.parentNode;
      if (parentNode) {
        parentNode.removeChild(this.overlayElement);
      }
    }
    teardown() {
      var _this$mapComponent2;
      // This calls onRemove.
      (_this$mapComponent2 = this.mapComponent) === null || _this$mapComponent2 === void 0 ? void 0 : _this$mapComponent2.setMap(null);
      this.overlayElement = null;
      this.container = null;
    }
    getOverlay(element) {
      this.overlayElement = element;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _window, _window$document;
      return (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.createDocumentFragment();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getOverlay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getOverlay"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OverlayView);
});