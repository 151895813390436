define("ember-paper/components/paper-card-content", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/require-tagless-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCardContent
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    tagName: 'md-card-content'
  });
});