define("ember-paper/components/paper-ink-bar", ["exports", "@ember/object", "@ember/component", "@ember/string"], function (_exports, _object, _component, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/require-tagless-components */
  var _default = _exports.default = _component.default.extend({
    tagName: 'md-ink-bar',
    attributeBindings: ['style'],
    classNameBindings: ['movingRight:md-right:md-left'],
    style: (0, _object.computed)('left', 'right', function () {
      return (0, _string.htmlSafe)(`left: ${this.left}px; right: ${this.right}px;`);
    })
  });
});