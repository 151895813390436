define("ember-svg-jar/inlined/bnb-icon-yay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M125 93l-16 32V85H93v32L69 93H53l24 32v48h48v-48l16-32z\" fill=\"#98fbd0\" stroke=\"#8a4fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M157 69c-.47 0-.93.03-1.39.07.9-2.52 1.39-5.24 1.39-8.07 0-13.25-10.75-24-24-24-3.12 0-6.11.6-8.84 1.69C121.38 28.5 112.07 21 101 21c-8.21 0-15.46 4.13-19.79 10.42A31.772 31.772 0 0069 29c-17.67 0-32 14.33-32 32 0 .13.01.27.01.4C27.88 63.25 21 71.32 21 81c0 11.05 8.95 20 20 20 1.38 0 2.72-.14 4.02-.4 0 .14-.02.27-.02.4 0 8.84 7.16 16 16 16s16-7.16 16-16c0-.77-.07-1.52-.18-2.27 2.4 1.43 5.19 2.27 8.18 2.27 4.26 0 8.12-1.68 10.99-4.39C100.08 104 107.96 109 117 109c3.27 0 6.39-.66 9.23-1.84 2.41 5.78 8.11 9.84 14.77 9.84 8.84 0 16-7.16 16-16 8.84 0 16-7.16 16-16s-7.16-16-16-16z\" fill=\"#2addb2\" stroke=\"#8a4fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path d=\"M93 149c0 4.42 3.58 8 8 8s8-3.58 8-8z\" fill=\"#8a4fff\"/><circle cx=\"89\" cy=\"137\" fill=\"#8a4fff\" r=\"4\"/><circle cx=\"113\" cy=\"137\" fill=\"#8a4fff\" r=\"4\"/><path d=\"M121.56 61.44c-2.7-5.02-8-8.44-14.1-8.44-3.29 0-6.35.99-8.9 2.7-2.19-6.23-8.12-10.7-15.1-10.7M48.82 80.23c1.26-.95 2.67-1.73 4.18-2.28 1.7-.62 3.54-.96 5.46-.96 3.29 0 6.35.99 8.9 2.7 2.19-6.23 8.12-10.7 15.1-10.7\" fill=\"none\" stroke=\"#8a4fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><circle cx=\"91\" cy=\"135\" fill=\"#fff\" r=\"2\"/><circle cx=\"115\" cy=\"135\" fill=\"#fff\" r=\"2\"/><g stroke=\"#8a4fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" stroke-width=\"2\"><path d=\"M125 149l36-12\" fill=\"#fff\"/><circle cx=\"161\" cy=\"137\" fill=\"#2addb2\" r=\"4\"/><path d=\"M77 149l-36-12\" fill=\"#fff\"/><circle cx=\"41\" cy=\"137\" fill=\"#2addb2\" r=\"4\"/></g>",
    "attrs": {
      "viewBox": "0 0 194 194",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});