define("ember-geoservice/services/geolocation", ["exports", "@ember/debug", "@glimmer/tracking", "@ember/object/evented", "@ember/service"], function (_exports, _debug, _tracking, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Geolocation = _exports.default = (_class = class Geolocation extends _service.default.extend(_evented.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentLocation", _descriptor, this);
      _defineProperty(this, "trackingCallback", null);
      _initializerDefineProperty(this, "watcherId", _descriptor2, this);
    }
    get geolocator() {
      return window.navigator.geolocation;
    }
    _handleNewPosition(geoObject) {
      if (geoObject) {
        this.currentLocation = [geoObject.coords.latitude, geoObject.coords.longitude];
        const callback = this.trackingCallback;
        if (callback) {
          callback(geoObject);
        }
        this.trigger('geolocationSuccess', geoObject);
      } else {
        this.currentLocation = null;
      }
    }
    getLocation(geoOptions) {
      return new Promise((resolve, reject) => {
        this.geolocator.getCurrentPosition(geoObject => {
          this._handleNewPosition(geoObject);
          resolve(geoObject);
        }, reason => {
          this.trigger('geolocationFail', reason);
          reject(reason);
        }, geoOptions);
      });
    }
    trackLocation(geoOptions, callback) {
      let watcherId = this.watcherId;
      (true && !('Warning: `trackLocation` was called but a tracker is already set') && (0, _debug.assert)(watcherId == null, 'Warning: `trackLocation` was called but a tracker is already set'));
      if (callback != null) {
        (true && !('callback should be a function') && (0, _debug.assert)(typeof callback === 'function', 'callback should be a function'));
      }
      this.trackingCallback = callback;
      return new Promise((resolve, reject) => {
        watcherId = this.geolocator.watchPosition(geoObject => {
          // make sure this logic is run only once
          if (resolve) {
            this.watcherId = watcherId;
            resolve(geoObject);
            resolve = null;
          }
          this._handleNewPosition(geoObject);
        }, reason => {
          this.trigger('geolocationFail', reason);
          reject(reason);
        }, geoOptions);
      });
    }
    stopTracking(clearLocation) {
      let watcher = this.watcherId;
      (true && !("Warning: `stopTracking` was called but location isn't tracked") && (0, _debug.assert)(watcher != null, "Warning: `stopTracking` was called but location isn't tracked"));
      this.geolocator.clearWatch(watcher);
      this.watcherId = null;
      if (clearLocation === true) {
        this._handleNewPosition(null);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "watcherId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class);
});