define("ember-paper/components/paper-toast", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/object/internals", "@ember/application", "ember-paper/templates/components/paper-toast", "ember-paper/utils/invoke-action"], function (_exports, _service, _computed, _component, _object, _runloop, _internals, _application, _paperToast, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/require-computed-property-dependencies, ember/no-component-lifecycle-hooks, ember/no-get */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperToast
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperToast.default,
    tagName: '',
    escapeToClose: false,
    swipeToClose: true,
    capsule: false,
    duration: 3000,
    position: 'bottom left',
    left: (0, _object.computed)('position', function () {
      let [, x] = this.position.split(' ');
      return x === 'left';
    }),
    top: (0, _object.computed)('position', function () {
      let [y] = this.position.split(' ');
      return y === 'top';
    }),
    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#paper-toast-fab-wormhole',
    defaultedParent: (0, _computed.or)('parent', 'wormholeSelector'),
    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: (0, _object.computed)('defaultedParent', function () {
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config.environment === 'test' && !this.parent) {
        return '#ember-testing';
      }
      let parent = this.defaultedParent;
      let parentEle = typeof parent === 'string' ? document.querySelector(parent) : parent;

      // If the parent isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.
      if (typeof parent === 'string' && parent.charAt(0) === '#') {
        return `#${parent.substring(1)}`;
      } else {
        let {
          id
        } = parentEle;
        if (!id) {
          id = `${this.uniqueId}-parent`;
          parentEle.id = id;
        }
        return `#${id}`;
      }
    }),
    // Find the element referenced by destinationId
    destinationEl: (0, _object.computed)('destinationId', function () {
      return document.querySelector(this.destinationId);
    }),
    constants: (0, _service.inject)(),
    _destroyMessage() {
      if (!this.isDestroyed) {
        (0, _invokeAction.invokeAction)(this, 'onClose');
      }
    },
    init() {
      this._super(...arguments);
      this.uniqueId = (0, _internals.guidFor)(this);
    },
    willInsertElement() {
      this._super(...arguments);
      document.querySelector(this.destinationId).classList.add('md-toast-animating');
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.duration !== false) {
        _runloop.run.later(this, '_destroyMessage', this.duration);
      }
      if (this.escapeToClose) {
        // Adding Listener to body tag, FIXME
        this._escapeToClose = _runloop.run.bind(this, e => {
          if (e.keyCode === this.get('constants.KEYCODE.ESCAPE') && this.onClose) {
            this._destroyMessage();
          }
        });
        document.body.addEventListener('keydown', this._escapeToClose);
      }
      let y = this.top ? 'top' : 'bottom';
      document.querySelector(this.destinationId).classList.add(`md-toast-open-${y}`);
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.escapeToClose) {
        document.body.removeEventListener('keydown', this._escapeToClose);
        this._escapeToClose = null;
      }
      let y = this.top ? 'top' : 'bottom';
      document.querySelector(this.destinationId).classList.remove(`md-toast-open-${y}`, 'md-toast-animating');
    }
  });
});