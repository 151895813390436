define("ember-svg-jar/inlined/bnb-icon-namaste", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#8a4fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\" stroke-width=\"2\"><path d=\"M125 93l-16 32V85H93v32L69 93H53l24 32v48h48v-48l16-32z\" fill=\"#98fbd0\"/><path d=\"M157 69c-.47 0-.93.03-1.39.07.9-2.52 1.39-5.24 1.39-8.07 0-13.25-10.75-24-24-24-3.12 0-6.11.6-8.84 1.69C121.38 28.5 112.07 21 101 21c-8.21 0-15.46 4.13-19.79 10.42A31.772 31.772 0 0069 29c-17.67 0-32 14.33-32 32 0 .13.01.27.01.4C27.88 63.25 21 71.32 21 81c0 11.05 8.95 20 20 20 1.38 0 2.72-.14 4.02-.4 0 .14-.02.27-.02.4 0 8.84 7.16 16 16 16s16-7.16 16-16c0-.77-.07-1.52-.18-2.27 2.4 1.43 5.19 2.27 8.18 2.27 4.26 0 8.12-1.68 10.99-4.39C100.08 104 107.96 109 117 109c3.27 0 6.39-.66 9.23-1.84 2.41 5.78 8.11 9.84 14.77 9.84 8.84 0 16-7.16 16-16 8.84 0 16-7.16 16-16s-7.16-16-16-16z\" fill=\"#2addb2\"/><g fill=\"none\"><path d=\"M121.56 61.44c-2.7-5.02-8-8.44-14.1-8.44-3.29 0-6.35.99-8.9 2.7-2.19-6.23-8.12-10.7-15.1-10.7M48.82 80.23c1.26-.95 2.67-1.73 4.18-2.28 1.7-.62 3.54-.96 5.46-.96 3.29 0 6.35.99 8.9 2.7 2.19-6.23 8.12-10.7 15.1-10.7M93 133c0 2.21-1.79 4-4 4s-4-1.79-4-4M117 133c0 2.21-1.79 4-4 4s-4-1.79-4-4M77 147c-3.31 0-6 2.69-6 6s2.69 6 6 6l22-4 2-8M125 147c3.31 0 6 2.69 6 6s-2.69 6-6 6l-22-4-2-8M99 140.11s2 2 4 0\"/></g></g>",
    "attrs": {
      "viewBox": "0 0 194 194",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});