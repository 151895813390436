define("ember-paper/templates/components/paper-tabs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hCHpCMXF",
    "block": "[[[10,\"md-tabs-wrapper\"],[15,0,[52,[33,1],\"md-stretch-tabs\"]],[12],[1,\"\\n\\n\"],[41,[33,2],[[[1,\"    \"],[10,\"md-prev-button\"],[14,\"role\",\"button\"],[15,0,[52,[51,[33,4]],\"md-disabled\"]],[15,\"onclick\",[28,[37,5],[[30,0],\"previousPage\"],null]],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"keyboard-arrow-left\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"md-next-button\"],[14,\"role\",\"button\"],[15,0,[52,[51,[33,7]],\"md-disabled\"]],[15,\"onclick\",[28,[37,5],[[30,0],\"nextPage\"],null]],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"keyboard-arrow-left\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"md-tabs-canvas\"],[15,0,[29,[[52,[33,2],\"md-paginated\"],\" \",[52,[33,8],\"md-center-tabs\"]]]],[14,\"tabindex\",\"-1\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n    \"],[10,\"md-pagination-wrapper\"],[15,0,[52,[33,8],\"md-center-tabs\"]],[15,5,[52,[33,2],[33,9]]],[12],[1,\"\\n\\n      \"],[18,1,[[28,[37,11],null,[[\"tab\"],[[50,\"paper-tab\",0,null,[[\"noInk\",\"selected\",\"onSelect\"],[[33,13],[33,14],[28,[37,5],[[30,0],\"localOnChange\"],null]]]]]]]]],[1,\"\\n\\n\"],[41,[33,15],[[[1,\"        \"],[8,[39,16],null,[[\"@movingRight\",\"@left\",\"@right\"],[[99,17,[\"@movingRight\"]],[33,15,[\"left\"]],[33,15,[\"right\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13]],[\"&default\"],false,[\"if\",\"shouldStretch\",\"shouldPaginate\",\"unless\",\"canPageBack\",\"action\",\"paper-icon\",\"canPageForward\",\"shouldCenter\",\"paginationStyle\",\"yield\",\"hash\",\"component\",\"noInk\",\"selected\",\"inkBar\",\"paper-ink-bar\",\"movingRight\"]]",
    "moduleName": "ember-paper/templates/components/paper-tabs.hbs",
    "isStrictMode": false
  });
});