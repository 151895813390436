define("ember-paper/components/paper-form", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "ember-paper/templates/components/paper-form", "ember-paper/mixins/parent-mixin", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _computed, _component2, _paperForm, _parentMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-mixins, ember/classic-decorator-no-classic-methods */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class PaperForm
   * @extends Ember.Component
   * @uses ParentMixin
   */
  let PaperForm = _exports.default = (_dec = (0, _component.layout)(_paperForm.default), _dec2 = (0, _component.tagName)('form'), _dec3 = (0, _computed.not)('isInvalid'), _dec4 = (0, _object.computed)('childComponents.@each.isInvalid'), _dec5 = (0, _object.computed)('childComponents.@each.isTouched'), _dec6 = (0, _computed.and)('isInvalid', 'isTouched'), _dec(_class = _dec2(_class = (_class2 = class PaperForm extends _component2.default.extend(_parentMixin.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "inputComponent", 'paper-input');
      _defineProperty(this, "submitButtonComponent", 'paper-button');
      _defineProperty(this, "selectComponent", 'paper-select');
      _defineProperty(this, "autocompleteComponent", 'paper-autocomplete');
      _initializerDefineProperty(this, "isValid", _descriptor, this);
      _initializerDefineProperty(this, "isInvalidAndTouched", _descriptor2, this);
    }
    get isInvalid() {
      return this.childComponents.isAny('isInvalid');
    }
    get isTouched() {
      return this.childComponents.isAny('isTouched');
    }
    submit() {
      this.send('localOnSubmit');
      return false;
    }
    localOnValidityChange() {
      if (this.lastIsValid !== this.isValid || this.lastIsTouched !== this.isTouched) {
        (0, _invokeAction.invokeAction)(this, 'onValidityChange', this.isValid, this.isTouched, this.isInvalidAndTouched);
        this.set('lastIsValid', this.isValid);
        this.set('lastIsTouched', this.isTouched);
      }
    }
    localOnSubmit() {
      if (this.isInvalid) {
        this.childComponents.setEach('isTouched', true);
        (0, _invokeAction.invokeAction)(this, 'onInvalid');
      } else {
        (0, _invokeAction.invokeAction)(this, 'onSubmit');
        this.childComponents.setEach('isTouched', false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "isValid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isInvalid", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isInvalid"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isTouched", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isTouched"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isInvalidAndTouched", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "localOnValidityChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "localOnValidityChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "localOnSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "localOnSubmit"), _class2.prototype), _class2)) || _class) || _class);
});