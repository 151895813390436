define("ember-paper/components/paper-tab", ["exports", "@ember-decorators/component", "@ember/object", "@ember/component", "@ember/string", "ember-paper/templates/components/paper-tab", "ember-composability-tools", "ember-paper/mixins/focusable-mixin", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _component2, _string, _paperTab, _emberComposabilityTools, _focusableMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-mixins, ember/classic-decorator-hooks, ember/classic-decorator-no-classic-methods */
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperTab = _exports.default = (_dec = (0, _component.layout)(_paperTab.default), _dec2 = (0, _component.tagName)('md-tab-item'), _dec3 = (0, _component.classNames)('md-tab'), _dec4 = (0, _component.classNameBindings)('isSelected:md-active'), _dec5 = (0, _component.attributeBindings)('isSelected:aria-selected', 'style', 'maybeHref:href'), _dec6 = (0, _object.computed)('href'), _dec7 = (0, _object.computed)('href', 'disabled'), _dec8 = (0, _object.computed)('selected', 'value'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = _dec5(_class = (_class2 = class PaperTab extends _component2.default.extend(_emberComposabilityTools.ChildMixin, _focusableMixin.default) {
    // <a> tags have browser styles or are usually styled by the user
    // this makes sure that tab item still looks good with an anchor tag
    get style() {
      if (this.href) {
        return (0, _string.htmlSafe)('text-decoration: none; border: none;');
      } else {
        return undefined;
      }
    }
    get maybeHref() {
      if (this.href && !this.disabled) {
        return this.href;
      } else {
        return undefined;
      }
    }
    get isSelected() {
      return this.selected === this.value;
    }
    init() {
      super.init(...arguments);
      if (this.href) {
        this.set('tagName', 'a');
      }
    }

    // this method is called by the parent
    updateDimensions() {
      // this is the true current width
      // it is used to calculate the ink bar position & pagination offset
      this.setProperties({
        left: this.element.offsetLeft,
        width: this.element.offsetWidth
      });
    }
    click() {
      if (!this.disabled) {
        (0, _invokeAction.invokeAction)(this, 'onClick', ...arguments);
        (0, _invokeAction.invokeAction)(this, 'onSelect', this);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "style", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "maybeHref", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "maybeHref"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _class2)) || _class) || _class) || _class) || _class) || _class);
});