define("ember-paper/components/paper-grid-tile", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "@ember/runloop", "ember-paper/templates/components/paper-grid-tile", "ember-composability-tools", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _computed, _component2, _runloop, _paperGridTile, _emberComposabilityTools, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/require-computed-property-dependencies, ember/no-get, ember/classic-decorator-no-classic-methods */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const positionCSS = positions => {
    return `calc((${positions.unit} + ${positions.gutter}) * ${positions.offset})`;
  };
  const dimensionCSS = dimensions => {
    return `calc((${dimensions.unit}) * ${dimensions.span} + (${dimensions.span} - 1) * ${dimensions.gutter})`;
  };
  const unitCSS = units => {
    return `${units.share}% - (${units.gutter} * ${units.gutterShare})`;
  };
  const applyStyles = (el, styles) => {
    for (let key in styles) {
      el.style[key] = styles[key];
    }
  };

  /**
   * @class PaperGridTile
   * @extends Ember.Component
   */
  let PaperGridTile = _exports.default = (_dec = (0, _component.layout)(_paperGridTile.default), _dec2 = (0, _component.tagName)('md-grid-tile'), _dec3 = (0, _computed.alias)('parentComponent'), _dec4 = (0, _object.computed)('colspan'), _dec5 = (0, _object.computed)('colspanMedia', 'gridList.currentMedia.[]'), _dec6 = (0, _object.computed)('rowspan'), _dec7 = (0, _object.computed)('rowspanMedia', 'gridList.currentMedia.[]'), _dec(_class = _dec2(_class = (_class2 = class PaperGridTile extends _component2.default.extend(_emberComposabilityTools.ChildMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "gridList", _descriptor, this);
    }
    didUpdateAttrs() {
      super.didUpdateAttrs(...arguments);
      let gridList = this.gridList;

      // Debounces until the next run loop
      _runloop.run.debounce(gridList, gridList.updateGrid, 0);
    }
    updateTile() {
      applyStyles(this.element, this._tileStyle());
      (0, _invokeAction.invokeAction)(this, 'onUpdate');
    }
    get colspanMedia() {
      return this.gridList._extractResponsiveSizes(this.colspan);
    }
    get currentColspan() {
      let colspan = this.gridList._getAttributeForMedia(this.colspanMedia, this.get('gridList.currentMedia'));
      return parseInt(colspan, 10) || 1;
    }
    get rowspanMedia() {
      return this.gridList._extractResponsiveSizes(this.rowspan);
    }
    get currentRowspan() {
      let rowspan = this.gridList._getAttributeForMedia(this.rowspanMedia, this.get('gridList.currentMedia'));
      return parseInt(rowspan, 10) || 1;
    }
    _tileStyle() {
      let position = this.position;
      let currentColspan = this.currentColspan;
      let currentRowspan = this.currentRowspan;
      let rowCount = this.get('gridList.rowCount');
      let colCount = this.get('gridList.currentCols');
      let gutter = this.get('gridList.currentGutter');
      let rowMode = this.get('gridList.currentRowMode');
      let rowHeight = this.get('gridList.currentRowHeight');

      // Percent of the available horizontal space that one column takes up.
      let hShare = 1 / colCount * 100;

      // Fraction of the gutter size that each column takes up.
      let hGutterShare = (colCount - 1) / colCount;

      // Base horizontal size of a column.
      let hUnit = unitCSS({
        share: hShare,
        gutterShare: hGutterShare,
        gutter
      });

      // The width and horizontal position of each tile is always calculated the same way, but the
      // height and vertical position depends on the rowMode.
      let style = {
        left: positionCSS({
          unit: hUnit,
          offset: position.col,
          gutter
        }),
        width: dimensionCSS({
          unit: hUnit,
          span: currentColspan,
          gutter
        }),
        // resets
        paddingTop: '',
        marginTop: '',
        top: '',
        height: ''
      };
      let vShare, vUnit;
      switch (rowMode) {
        case 'fixed':
          {
            // In fixed mode, simply use the given rowHeight.
            style.top = positionCSS({
              unit: rowHeight,
              offset: position.row,
              gutter
            });
            style.height = dimensionCSS({
              unit: rowHeight,
              span: currentRowspan,
              gutter
            });
            break;
          }
        case 'ratio':
          {
            // Percent of the available vertical space that one row takes up. Here, rowHeight holds
            // the ratio value. For example, if the width:height ratio is 4:3, rowHeight = 1.333.
            vShare = hShare / rowHeight;

            // Base veritcal size of a row.
            vUnit = unitCSS({
              share: vShare,
              gutterShare: hGutterShare,
              gutter
            });

            // paddingTop and marginTop are used to maintain the given aspect ratio, as
            // a percentage-based value for these properties is applied to the *width* of the
            // containing block. See http://www.w3.org/TR/CSS2/box.html#margin-properties
            style.paddingTop = dimensionCSS({
              unit: vUnit,
              span: currentRowspan,
              gutter
            });
            style.marginTop = positionCSS({
              unit: vUnit,
              offset: position.row,
              gutter
            });
            break;
          }
        case 'fit':
          {
            // Fraction of the gutter size that each column takes up.
            let vGutterShare = (rowCount - 1) / rowCount;

            // Percent of the available vertical space that one row takes up.
            vShare = 1 / rowCount * 100;

            // Base vertical size of a row.
            vUnit = unitCSS({
              share: vShare,
              gutterShare: vGutterShare,
              gutter
            });
            style.top = positionCSS({
              unit: vUnit,
              offset: position.row,
              gutter
            });
            style.height = dimensionCSS({
              unit: vUnit,
              span: currentRowspan,
              gutter
            });
            break;
          }
      }
      return style;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "gridList", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "colspanMedia", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "colspanMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentColspan", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "currentColspan"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rowspanMedia", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "rowspanMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentRowspan", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "currentRowspan"), _class2.prototype), _class2)) || _class) || _class);
});