define("ember-svg-jar/inlined/broccoli-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon_final</title><path d=\"M42.3 19.4c0 3.192-1.843 3.686-3.687 4.793 0 2.028 0 3.503-2.765 4.424-1.935.646-3.87-.967-4.858-1.993-1.518 2.025-2.875 4.266-3.36 6.285-1.315 5.483-1.949 12.372-1.764 14.58.184 2.206-8.661-.786-8.661-.786s0-13.794-3.844-17.652a5.05 5.05 0 01-.623-.755c-.87.694-1.976 1.071-3.165 1.071-.874 0-1.62-.295-2.23-.756-1.162-.879-1.26-3.238-1.267-4.65v-.52C3.496 23.257.3 22.23.3 18.358c0-3.872 2.18-4.121 4.762-5.227-.738-2.766-1.759-6.07 1.688-8.481 3.446-2.412 6.976-.738 9.373 0C16.49 3.912 18.149.7 23.769.7s5.995 3.397 6.917 5.794c1.66 0 3.261-1.27 5.673 1.255 2.413 2.526 1.148 4.644 1.148 6.488 1.66 0 4.793 1.969 4.793 5.162z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "43",
      "height": "49",
      "viewBox": "0 0 43 49",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});