define("ember-paper/components/paper-menu/item/component", ["exports", "@ember/component", "ember-paper/components/paper-menu/item/template", "@ember/object", "@ember/object/computed", "@ember-decorators/component"], function (_exports, _component, _template, _object, _computed, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor;
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PaperMenuItem = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _computed.or)('onClick', 'href'), _dec(_class = _dec2(_class = (_class2 = class PaperMenuItem extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "shouldRenderButton", _descriptor, this);
    }
    handleClick() {
      this.dropdown.actions.close();
      if (typeof this.onClick === 'function') {
        this.onClick(...arguments);
      }
    }
    handleMouseEnter(event) {
      if (!this.disabled) {
        let button = event.target.querySelector('button');
        if (button) {
          button.focus();
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "shouldRenderButton", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseEnter"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = PaperMenuItem;
});