define("ember-paper/utils/unwrap-proxy", ["exports", "@ember/array/proxy", "@ember/object/proxy", "@ember/object"], function (_exports, _proxy, _proxy2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unwrapProxy;
  _exports.isProxy = isProxy;
  /* eslint-disable ember/no-get */

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy((0, _object.get)(o, 'content')) : o;
  }
  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }
});