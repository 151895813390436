define("ember-paper/components/paper-select/search/index", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/before-options"], function (_exports, _component, _templateFactory, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-down-event-binding require-input-label }}
  {{#if @searchEnabled}}
    <md-select-header class="ember-power-select-search md-searchbox">
      <input type="search" autocomplete="off"
        autocorrect="off" autocapitalize="off"
        spellcheck="false" role="combobox"
        class="ember-power-select-search-input md-searchinput"
        value={{@select.searchText}}
        aria-controls={{@listboxId}}
        placeholder={{@searchPlaceholder}}
        {{on "input" @onInput}}
        {{on "focus" @onFocus}}
        {{on "blur" @onBlur}}
        {{on "keydown" this.handleKeydown}}
        {{did-insert this.focusInput}}>
    </md-select-header>
  {{/if}}
  */
  {
    "id": "JCCDJkul",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"md-select-header\"],[14,0,\"ember-power-select-search md-searchbox\"],[12],[1,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[24,\"spellcheck\",\"false\"],[24,\"role\",\"combobox\"],[24,0,\"ember-power-select-search-input md-searchinput\"],[16,2,[30,2,[\"searchText\"]]],[16,\"aria-controls\",[30,3]],[16,\"placeholder\",[30,4]],[24,4,\"search\"],[4,[38,1],[\"input\",[30,5]],null],[4,[38,1],[\"focus\",[30,6]],null],[4,[38,1],[\"blur\",[30,7]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeydown\"]]],null],[4,[38,2],[[30,0,[\"focusInput\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@searchEnabled\",\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\"],false,[\"if\",\"on\",\"did-insert\"]]",
    "moduleName": "ember-paper/components/paper-select/search/index.hbs",
    "isStrictMode": false
  });
  class PaperSelectOptions extends _beforeOptions.default {}
  _exports.default = PaperSelectOptions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaperSelectOptions);
});