define("ember-paper/components/paper-toolbar", ["exports", "@ember/component", "ember-paper/templates/components/paper-toolbar", "ember-paper/mixins/color-mixin"], function (_exports, _component, _paperToolbar, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/no-mixins, ember/require-tagless-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperToolbar
   * @extends Ember.Component
   * @uses ColorMixin
   */
  var _default = _exports.default = _component.default.extend(_colorMixin.default, {
    layout: _paperToolbar.default,
    tagName: 'md-toolbar',
    classNames: ['md-default-theme'],
    tall: false,
    classNameBindings: ['tall:md-tall']
  });
});