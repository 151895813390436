define("ember-paper/components/paper-autocomplete/ebd-content/component", ["exports", "@ember/component", "ember-paper/components/paper-autocomplete/ebd-content/template", "@ember-decorators/component"], function (_exports, _component, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  /* eslint-disable ember/no-classic-components */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let PaperAutocompleteEbdContent = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec(_class = _dec2(_class = class PaperAutocompleteEbdContent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "customStyles", {
        'overflow-y': 'auto',
        'overflow-x': 'hidden',
        'height': 'auto!important'
      });
    }
    shouldReposition(mutations) {
      let shouldReposition = false;
      shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }
        return false;
      });
      return shouldReposition;
    }
  }) || _class) || _class);
  var _default = _exports.default = PaperAutocompleteEbdContent;
});