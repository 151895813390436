define("ember-paper/utils/get-parent", ["exports", "@ember/object", "ember"], function (_exports, _object, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getParent;
  /* eslint-disable ember/no-get */

  const {
    ViewUtils
  } = _ember.default;

  // taken from https://github.com/kaliber5/ember-bootstrap/blob/master/addon/utils/get-parent.js
  function getParent(view) {
    if ((0, _object.get)(view, 'tagName') === '') {
      // Beware: use of private API! :(
      if (ViewUtils && ViewUtils.getViewBounds) {
        return ViewUtils.getViewBounds(view).parentElement;
      } else {
        return view._renderNode.contextualElement;
      }
    } else {
      return (0, _object.get)(view, 'element').parentNode;
    }
  }
});