define("ember-paper/components/paper-grid-list", ["exports", "@ember/service", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-grid-list", "ember-composability-tools", "ember-paper/utils/grid-layout", "ember-paper/utils/invoke-action"], function (_exports, _service, _component, _component2, _object, _runloop, _paperGridList, _emberComposabilityTools, _gridLayout, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/classic-decorator-no-classic-methods, ember/no-classic-components, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-get */
  /**
   * @module ember-paper
   */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const mediaRegex = /(^|\s)((?:print-)|(?:[a-z]{2}-){1,2})?(\d+)(?!\S)/g;
  const rowHeightRegex = /(^|\s)((?:print-)|(?:[a-z]{2}-){1,2})?(\d+(?:[a-z]{2,3}|%)?|\d+:\d+|fit)(?!\S)/g;
  const unitCSS = units => {
    return `${units.share}% - (${units.gutter} * ${units.gutterShare})`;
  };
  const dimensionCSS = dimensions => {
    return `calc((${dimensions.unit}) * ${dimensions.span} + (${dimensions.span} - 1) * ${dimensions.gutter})`;
  };
  const media = mediaName => {
    return mediaName.charAt(0) !== '(' ? `(${mediaName})` : mediaName;
  };
  const mediaListenerName = name => {
    return `${name.replace('-', '')}Listener`;
  };
  const applyStyles = (el, styles) => {
    for (let key in styles) {
      el.style[key] = styles[key];
    }
  };
  let PaperGridList = _exports.default = (_dec = (0, _component.tagName)('md-grid-list'), _dec2 = (0, _component.layout)(_paperGridList.default), _dec3 = (0, _object.computed)('cols'), _dec4 = (0, _object.computed)('colsMedia', 'currentMedia.[]'), _dec5 = (0, _object.computed)('gutter'), _dec6 = (0, _object.computed)('gutterMedia', 'currentMedia.[]'), _dec7 = (0, _object.computed)('rowHeight'), _dec8 = (0, _object.computed)('rowHeightMedia', 'currentMedia.[]'), _dec(_class = _dec2(_class = (_class2 = class PaperGridList extends _component2.default.extend(_emberComposabilityTools.ParentMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "constants", _descriptor, this);
    }
    get tiles() {
      return this.childComponents;
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this._installMediaListener();
    }
    didUpdate() {
      super.didUpdate(...arguments);

      // Debounces until the next run loop
      _runloop.run.debounce(this, this.updateGrid, 0);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this._uninstallMediaListener();
    }

    // Sets up a listener for each media query
    _installMediaListener() {
      for (let mediaName in this.get('constants.MEDIA')) {
        let query = this.get('constants.MEDIA')[mediaName] || media(mediaName);
        let mediaList = window.matchMedia(query);
        let listenerName = mediaListenerName(mediaName);

        // Sets mediaList to a property so removeListener can access it
        this.set(`${listenerName}List`, mediaList);

        // Creates a function based on mediaName so that removeListener can remove it.
        this.set(listenerName, _runloop.run.bind(this, result => {
          this._mediaDidChange(mediaName, result.matches);
        }));

        // Trigger initial grid calculations
        this._mediaDidChange(mediaName, mediaList.matches);
        mediaList.addListener(this[listenerName]);
      }
    }
    _uninstallMediaListener() {
      for (let mediaName in this.get('constants.MEDIA')) {
        let listenerName = mediaListenerName(mediaName);
        let mediaList = this.get(`${listenerName}List`);
        mediaList.removeListener(this[listenerName]);
      }
    }
    _mediaDidChange(mediaName, matches) {
      this.set(mediaName, matches);

      // Debounces until the next run loop
      _runloop.run.debounce(this, this._updateCurrentMedia, 0);
    }
    _updateCurrentMedia() {
      let mediaPriorities = this.get('constants.MEDIA_PRIORITY');
      let currentMedia = mediaPriorities.filter(mediaName => this.get(mediaName));
      this.set('currentMedia', currentMedia);
      this.updateGrid();
    }

    // Updates styles and triggers onUpdate callbacks
    updateGrid() {
      applyStyles(this.element, this._gridStyle());
      this.tiles.forEach(tile => tile.updateTile());
      (0, _invokeAction.invokeAction)(this, 'onUpdate');
    }
    _gridStyle() {
      this._setTileLayout();
      let style = {};
      let colCount = this.currentCols;
      let gutter = this.currentGutter;
      let rowHeight = this.currentRowHeight;
      let rowMode = this.currentRowMode;
      let rowCount = this.rowCount;
      switch (rowMode) {
        case 'fixed':
          {
            style.height = dimensionCSS({
              unit: rowHeight,
              span: rowCount,
              gutter
            });
            style.paddingBottom = '';
            break;
          }
        case 'ratio':
          {
            // rowHeight is width / height
            let hGutterShare = colCount === 1 ? 0 : (colCount - 1) / colCount;
            let hShare = 1 / colCount * 100;
            let vShare = hShare * (1 / rowHeight);
            let vUnit = unitCSS({
              share: vShare,
              gutterShare: hGutterShare,
              gutter
            });
            style.height = '';
            style.paddingBottom = dimensionCSS({
              unit: vUnit,
              span: rowCount,
              gutter
            });
            break;
          }
        case 'fit':
          {
            // rowHeight is container height
            style.height = '100%';
            break;
          }
      }
      return style;
    }

    // Calculates tile positions
    _setTileLayout() {
      let tiles = this.orderedTiles();
      let layoutInfo = (0, _gridLayout.default)(this.currentCols, tiles);
      tiles.forEach((tile, i) => tile.set('position', layoutInfo.positions[i]));
      this.set('rowCount', layoutInfo.rowCount);
    }

    // Sorts tiles by their order in the dom
    orderedTiles() {
      // Convert NodeList to native javascript array, to be able to use indexOf.
      let domTiles = Array.prototype.slice.call(this.element.querySelectorAll('md-grid-tile'));
      return this.tiles.sort((a, b) => {
        return domTiles.indexOf(a.get('element')) > domTiles.indexOf(b.get('element')) ? 1 : -1;
      });
    }

    // Parses attribute string and returns hash of media sizes
    _extractResponsiveSizes(string, regex = mediaRegex) {
      let matches = {};
      let match;
      while (match = regex.exec(string)) {
        if (match[2]) {
          matches[match[2].slice(0, -1)] = match[3];
        } else {
          matches.base = match[3];
        }
      }
      return matches;
    }

    // Gets attribute for current media
    _getAttributeForMedia(sizes, currentMedia) {
      for (let i = 0; i < currentMedia.length; i++) {
        if (sizes[currentMedia[i]]) {
          return sizes[currentMedia[i]];
        }
      }
      return sizes.base;
    }
    get colsMedia() {
      let sizes = this._extractResponsiveSizes(this.cols);
      if (Object.keys(sizes).length === 0) {
        throw new Error('md-grid-list: No valid cols found');
      }
      return sizes;
    }
    get currentCols() {
      return this._getAttributeForMedia(this.colsMedia, this.currentMedia) || 1;
    }
    get gutterMedia() {
      return this._extractResponsiveSizes(this.gutter, rowHeightRegex);
    }
    get currentGutter() {
      return this._applyDefaultUnit(this._getAttributeForMedia(this.gutterMedia, this.currentMedia) || 1);
    }
    get rowHeightMedia() {
      let rowHeights = this._extractResponsiveSizes(this.rowHeight, rowHeightRegex);
      if (Object.keys(rowHeights).length === 0) {
        throw new Error('md-grid-list: No valid rowHeight found');
      }
      return rowHeights;
    }
    get currentRowHeight() {
      let rowHeight = this._getAttributeForMedia(this.rowHeightMedia, this.currentMedia);
      // eslint-disable-next-line ember/no-side-effects
      this.set('currentRowMode', this._getRowMode(rowHeight));
      switch (this._getRowMode(rowHeight)) {
        case 'fixed':
          {
            return this._applyDefaultUnit(rowHeight);
          }
        case 'ratio':
          {
            let whRatio = rowHeight.split(':');
            return parseFloat(whRatio[0]) / parseFloat(whRatio[1]);
          }
        case 'fit':
          {
            return 0;
          }
      }
      return undefined;
    }
    _getRowMode(rowHeight) {
      if (rowHeight === 'fit') {
        return 'fit';
      } else if (rowHeight.indexOf(':') !== -1) {
        return 'ratio';
      } else {
        return 'fixed';
      }
    }
    _applyDefaultUnit(val) {
      return /\D$/.test(val) ? val : `${val}px`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "constants", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "colsMedia", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "colsMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentCols", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "currentCols"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gutterMedia", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "gutterMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentGutter", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "currentGutter"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rowHeightMedia", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "rowHeightMedia"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "currentRowHeight", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "currentRowHeight"), _class2.prototype), _class2)) || _class) || _class);
});