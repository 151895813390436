define("ember-async-await-helper/templates/components/async-await", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Wsn4LQpR",
    "block": "[[[41,[30,0,[\"isResolved\"]],[[[1,\"  \"],[18,1,[[30,0,[\"resolvedValue\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"&default\",\"&else\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-async-await-helper/templates/components/async-await.hbs",
    "isStrictMode": false
  });
});