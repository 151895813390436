define("ember-paper/components/paper-card", ["exports", "@ember/component", "ember-paper/templates/components/paper-card"], function (_exports, _component, _paperCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/require-tagless-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCard
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperCard.default,
    tagName: 'md-card'
  });
});