define("ember-paper/components/paper-toast-inner", ["exports", "@ember/component", "ember-paper/templates/components/paper-toast-inner"], function (_exports, _component, _paperToastInner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components */
  /**
   * @module ember-paper
   */
  /**
   * @class PaperToastInner
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperToastInner.default,
    tagName: ''
  });
});