define("ember-paper/components/paper-menu/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-paper/utils/menu-position-calculator", "ember-paper/components/paper-menu/template"], function (_exports, _component, _component2, _object, _menuPositionCalculator, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperMenu = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = (0, _object.computed)('position'), _dec4 = (0, _object.computed)('offset'), _dec(_class = _dec2(_class = (_class2 = class PaperMenu extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "position", 'target');
      _defineProperty(this, "offset", '0 0');
    }
    close() {
      this.didAnimateScale = false;
    }
    open() {
      this.didAnimateScale = false;
    }
    // If attachment is a single item, duplicate it for our second value.
    // ie. 'target' -> 'target target'
    get positionMode() {
      let position = this.position || 'target';
      let [left, top] = position.split(' ').map(s => s.trim());
      top = top || left;
      return {
        left,
        top
      };
    }
    get offsets() {
      let offset = this.offset || '0 0';
      let [left, top] = offset.split(' ').map(s => s.trim()).map(parseFloat);
      top = top || left;
      return {
        left,
        top
      };
    }
    calculatePosition(trigger, content) {
      let positionCalculator = new _menuPositionCalculator.default(trigger, content, this.positionMode, this.offsets, this.didAnimateScale);
      this.didAnimateScale = true;
      return positionCalculator.drowdownPosition;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "positionMode", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "positionMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "offsets", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "offsets"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "calculatePosition", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "calculatePosition"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = PaperMenu;
});