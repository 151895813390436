define("ember-paper/components/paper-item", ["exports", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "@ember/component", "ember-paper/templates/components/paper-item", "ember-composability-tools", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _computed, _component2, _paperItem, _emberComposabilityTools, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  /* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-classic-components, ember/no-component-lifecycle-hooks */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * @class PaperItem
   * @extends Ember.Component
   * @uses ParentMixin
   */
  let PaperItem = _exports.default = (_dec = (0, _component.layout)(_paperItem.default), _dec2 = (0, _component.tagName)('md-list-item'), _dec3 = (0, _component.classNameBindings)('hasProxiedComponent:md-proxy-focus', 'shouldBeClickable:md-clickable', 'focused:md-focused', 'hasPrimaryAction:_md-button-wrap'), _dec4 = (0, _component.attributeBindings)('role', 'tabindex', 'title'), _dec5 = (0, _object.computed)('hasPrimaryAction', 'hasProxiedComponent'), _dec6 = (0, _computed.filter)('childComponents', function (c) {
    return !c.get('skipProxy');
  }), _dec7 = (0, _computed.bool)('proxiedComponents.length'), _dec8 = (0, _computed.or)('hasProxiedComponent', 'onClick'), _dec9 = (0, _computed.or)('onClick', 'href'), _dec10 = (0, _object.computed)('hasPrimaryAction', 'hasProxiedComponent'), _dec11 = (0, _object.computed)('proxiedComponents.[]'), _dec(_class = _dec2(_class = _dec3(_class = _dec4(_class = (_class2 = class PaperItem extends _component2.default.extend(_emberComposabilityTools.ParentMixin) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_mouseEnterHandler", undefined);
      _defineProperty(this, "_mouseLeaveHandler", undefined);
      _defineProperty(this, "role", 'listitem');
      _defineProperty(this, "tabindex", '-1');
      _initializerDefineProperty(this, "proxiedComponents", _descriptor, this);
      _initializerDefineProperty(this, "hasProxiedComponent", _descriptor2, this);
      _initializerDefineProperty(this, "shouldBeClickable", _descriptor3, this);
      _initializerDefineProperty(this, "hasPrimaryAction", _descriptor4, this);
    }
    // Ripple Overrides
    // disable ripple when we have a primary action or when we don't have a proxied component
    get noink() {
      return this.hasPrimaryAction || !this.hasProxiedComponent;
    }
    get noProxy() {
      return !this.hasPrimaryAction && !this.hasProxiedComponent;
    }
    get secondaryItem() {
      let proxiedComponents = this.proxiedComponents;
      return proxiedComponents.objectAt(0);
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this._mouseEnterHandler = this.handleMouseEnter.bind(this);
      this._mouseLeaveHandler = this.handleMouseLeave.bind(this);
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      this._mouseEnterHandler = undefined;
      this._mouseLeaveHandler = undefined;
    }
    click() {
      this.proxiedComponents.forEach(component => {
        if (component.processProxy && !component.get('disabled') && component.get('bubbles') | !this.hasPrimaryAction) {
          component.processProxy();
        }
      });
    }
    handleMouseEnter(e) {
      (0, _invokeAction.invokeAction)(this, 'onMouseEnter', e);
    }
    handleMouseLeave(e) {
      (0, _invokeAction.invokeAction)(this, 'onMouseLeave', e);
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "noink", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "noink"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "proxiedComponents", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasProxiedComponent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "shouldBeClickable", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "hasPrimaryAction", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "noProxy", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "noProxy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "secondaryItem", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "secondaryItem"), _class2.prototype), _class2)) || _class) || _class) || _class) || _class);
});